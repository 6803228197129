import { __assign, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import Info from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/info/info';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/dist/env/hosts';
var VideoInfo = /** @class */ (function (_super) {
    __extends(VideoInfo, _super);
    function VideoInfo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VideoInfo.prototype.translateCrew = function (crew) {
        // eslint-disable-next-line arrow-parens
        return _.map(crew, function (crewMember) {
            var role = crewMember.role;
            if (!role) {
                return crewMember;
            }
            return __assign(__assign({}, crewMember), { role: i18n.t("shared.crew." + role) });
        });
    };
    VideoInfo.prototype.translateGenre = function (genre) {
        if (!genre) {
            return null;
        }
        return i18n.t("shared.genres." + genre);
    };
    VideoInfo.prototype.translateVideoType = function (videoType) {
        if (!videoType) {
            return null;
        }
        return i18n.t("shared.video-types." + videoType);
    };
    VideoInfo.prototype.translatePublisher = function (publisher) {
        if (!publisher) {
            return null;
        }
        return i18n.t('player.info-overlay.created-by', { author: publisher });
    };
    VideoInfo.prototype.render = function () {
        var _a = this.props, posterUrl = _a.posterUrl, title = _a.title, description = _a.description, publisher = _a.publisher, durationStr = _a.durationStr, durationAriaLabel = _a.durationAriaLabel, genre = _a.genre, videoType = _a.videoType, cast = _a.cast, crew = _a.crew, isPlayerInFullScreen = _a.isPlayerInFullScreen, isRTL = _a.isRTL;
        return (React.createElement(Info, { title: title, description: description, posterUrl: posterUrl, durationStr: durationStr, durationAriaLabel: durationAriaLabel, publisher: this.translatePublisher(publisher), genre: this.translateGenre(genre), videoType: this.translateVideoType(videoType), cast: cast, crew: this.translateCrew(crew), crewTitle: i18n.t('player.info-overlay.crew'), castTitle: i18n.t('player.info-overlay.cast'), isInFullScreen: isPlayerInFullScreen, imageHost: PRODUCTION_HOSTS.image, isRTL: isRTL }));
    };
    VideoInfo.propTypes = {
        posterUrl: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        publisher: PropTypes.string,
        durationStr: PropTypes.string,
        durationAriaLabel: PropTypes.string,
        genre: PropTypes.string,
        videoType: PropTypes.string,
        cast: PropTypes.array,
        crew: PropTypes.array,
        isPlayerInFullScreen: PropTypes.bool,
        isRTL: PropTypes.bool,
    };
    return VideoInfo;
}(React.Component));
export default VideoInfo;
