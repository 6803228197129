import _ from 'lodash';
import { createSelector } from 'reselect';
import { isScheduledLive, isReadyLiveStream, isPendingLiveStream, } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/live';
import { ItemType } from '@wix/wix-vod-constants/dist/api-types/enums/item-type';
var getVideos = function (state) { return _.get(state, 'entities.videos'); };
export var getLiveVideos = createSelector(getVideos, function (videos) {
    return _.filter(videos, function (video) {
        return _.includes([ItemType.ItemLive, ItemType.ItemScheduled, ItemType.ItemLiveFinalize], video.itemType);
    });
});
export var getLiveScheduledVideos = createSelector(getLiveVideos, function (videos) {
    return _.filter(videos, function (_a) {
        var itemType = _a.itemType;
        return isScheduledLive({ itemType: itemType });
    });
});
export var getLivePendingVideos = createSelector(getLiveVideos, function (videos) {
    return _.filter(videos, function (_a) {
        var itemType = _a.itemType, mediaInfo = _a.mediaInfo;
        return isPendingLiveStream({ itemType: itemType, mediaInfo: mediaInfo });
    });
});
export var getLiveStreamingVideos = createSelector(getLiveVideos, function (videos) {
    return _.filter(videos, function (_a) {
        var itemType = _a.itemType, mediaInfo = _a.mediaInfo;
        return isReadyLiveStream({ itemType: itemType, mediaInfo: mediaInfo });
    });
});
